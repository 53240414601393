<template>
	<div class="index">
		<div class="top">
			<img src="../assets/images/top.png" >
			<div class="erweim" :class="{'collection-show': shareState}" @click="tabshareState">
				<img src="../assets/images/pc_down.png" >
				<p>APP下载</p>
				<div class="tankuang" v-if="shareState">
					<img src="../assets/images/pc_ma.png" >
					<p>手机扫码下载</p>
				</div>
			</div>
			
		</div>
		<div class="bottom">
			<img src="../assets/images/banner1.png" >
			<p>© 杭州酷网科技有限公司   <span @click="tabopen">浙ICP备2022009522号-1</span>   由杭州天迈网络有限公司提供技术支持</p>
		</div>
		<div class="banner">
			<img src="../assets/images/banner.png" >
		</div>
		<div class="title">
			<img src="../assets/images/title.png" >
		</div>
		<div class="navtab">
			<img src="../assets/images/img1.png" >
			<img src="../assets/images/img2.png" >
			<img src="../assets/images/img3.png" >
			<img src="../assets/images/img4.png" >
			<img src="../assets/images/img5.png" >
		</div>
		<div class="title1">
			<img src="../assets/images/title1.png" >
		</div>
		<div class="navtab1">
			<img src="../assets/images/icon1.png" >
			<img src="../assets/images/icon2.png" >
			<img src="../assets/images/icon3.png" >
			<img src="../assets/images/icon4.png" >
		</div>
		<div class="hexinjz">
			<img src="../assets/images/hexinjz.png" >
		</div>
		<div class="title2">
			<img src="../assets/images/title2.png" >
		</div>
		<div class="navtab2">
			<img src="../assets/images/bottom1.png" >
			<img src="../assets/images/bottom2.png" >
			<img src="../assets/images/bottom3.png" >
			<img src="../assets/images/bottom4.png" >
		</div>
	</div>

</template>

<script>
	export default {
		name: 'index',
		data() {
			return {
                shareState:false
			}
		},
		methods: {
			tabopen(){
				window.open('https://beian.miit.gov.cn')
				
			},
			tabshareState(){
				this.shareState=!this.shareState
			}
		},
		mounted() {
			
		}
	}
</script>

<style scoped lang="less">

	
	ul li {
		list-style: none;
	}
	.index{
		position: relative;
		width: 100%;
		height: 100%;
		background-color: #101327;
		min-height: 100vh;
		.top{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			img{
				width: 100%;
				height: auto;
			}
			.erweim{
				position: absolute;
				top: 13px;
				right: 20%;
				display: flex;
				align-items: center;
				padding: 7px 15px;
				border-radius: 25px;
				border: 1px solid #fff;
				cursor: pointer;
				>img{
					width: 14px;
					height: 22px;
					margin-right: 5px;
					
				}
				>p{
					font-size: 16px;
					color: #ffffff;
				}
				.tankuang{
					box-sizing: border-box;
					position: absolute;
					top: 150%;
					left: -20px;
					width: 189px;
					height: 220px;
					background-image: url(../assets/images/tankuang.png);
					background-repeat: no-repeat;
					background-size: 100%;
					padding: 20px 15px;
					display: flex;
					flex-direction: column;
					align-items: center;
					>img{
						width: 152px;
						height: 152px;
						margin-bottom: 10px;
					}
					>p{
						font-size: 18px;
						color: #272727;
					}
				}
			}
			.collection-show{
				background-image: linear-gradient(90deg, 
						#5c13e7 0%, 
						#3a57e2 50%, 
						#07bcdb 100%), 
					linear-gradient(
						#ffffff, 
						#ffffff);
					background-blend-mode: normal, 
						normal;
				border: none;
				padding: 8px 16px;
			}
		}
		.banner{
			width: 100%;
			img{
				width: 100%;
				height: auto;
			}
		}
		.title{
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 103px 0 95px 0;
		}
		.navtab{
			box-sizing: border-box;
			width: 100%;
			padding: 0 354px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			img{
				width: 132px;
				height: auto;
			}
		}
		.title1{
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 152px 0 84px 0;
		}
		.navtab1{
			box-sizing: border-box;
			width: 100%;
			padding: 0 320px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding-bottom: 109px;
			img{
				width: 292px;
				height: auto;
			}
		}
		.hexinjz{
			width: 100%;
			img{
				width: 100%;
				height: auto;
			}
		}
		.title2{
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 78px 0 126px 0;
		}
		.navtab2{
			width: 100%;
			box-sizing: border-box;
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex-wrap: wrap;
			padding: 0 320px;
			padding-bottom: 171px;
			width: 100%;
			img{
				margin-bottom: 88px;
			}
		}
		.bottom{
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			img{
				width: 100%;
				height: auto;
			}
			p{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 12px;
				color: #888888;
				span{
					font-size: 12px;
					color: #888888;
					padding: 0 20px;
					cursor: pointer;
				}
				
			}
		}
	}

	
</style>
